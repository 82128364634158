// Helper for DOM redirection

define('app/redirect',["jquery"],function($) {

    return {

        target: function(el,target) {
            var clone = $(el).clone(true,true);
            $(target).html(clone);
            clone.removeClass('hidden');
        },

        liveTarget: function() {
            // TODO - Implement this
        },

        element: function(fromElement,toElement) {
            $(fromElement).livequery(function(){
               $(toElement).html($(fromElement).clone(true,true));
               $(toElement).remove();
            });
        }

    }
});
