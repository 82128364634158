define('modules/social/js/base.js',["app/config","jquery","app/util","app/module"], function(config,$,util,module) {
    return new module({
        name: 'social',
        selectors: false,
        remoteData: [],


        config: {
            twitterTpl: '<span>SET IN CONFIG</span>',
            googleTpl: '<span>SET IN CONFIG</span>',
            facebookTpl: '<span>SET IN CONFIG</span>'
        },

        initialize: function () {
            var clickFn = this.onSocialClick;
            $('body').livequery('.shareSocial,.shareButton',function(element){
                $(element).on('click',clickFn);
            },function(){
                $(element).off('click',clickFn);
            });
            $('body').livequery('.socialShareCounter',$.proxy(function(element){
                this.addShareCounter(element);
            },this));
        },

        methods: {

            // This method is scoped to the element itself, not the module class
            onSocialClick: function (e) {
                e.preventDefault();
                e.stopPropagation();

                var loc = location.href,
                    action = $(this).attr('data-action');

                // TODO share text config for custom buttons
                if (action == 'twitter') {
                    var title = $(this).attr('data-title') || document.title;
                    window.open('//twitter.com/intent/tweet?url=' + encodeURIComponent(loc) + '&text=' + encodeURIComponent(title), 'twitterwindow', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'facebook') {
                    var t = $(this).attr('data-title') || document.title;
                    window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(loc) + '&t=' + encodeURIComponent(t), 'sharer', 'status=0,width=626,height=436, top=' + (100) + ', left=' + ($(window).width() / 2 + 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'youtube') {
                    var title = $(this).attr('data-title') || document.title;
                    window.location.assign("https://www.youtube.com/watch?v=" + title);
                } else if (action == 'google') {
                    var gpshareText = $(this).find('.linkedin-share-text').text();
                    window.open('https://plus.google.com/share?url=' + encodeURIComponent(loc), 'Google Share', 'status=0,width=626,height=436, top=' + ($(window).height() / 2 - 225) + ', left=' + ($(window).width() / 2 - 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'linkedin') {
                    var title = $(this).attr('data-title') || document.title;
                    var lishareText = $(this).find('.linkedin-share-text').text();
                    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(loc) + '&title=' + encodeURIComponent(title) + '&summary=' + encodeURIComponent(lishareText), 'Linkedin Share', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                }
            },

            addShareCounter: function (element) {
                var action = $(element).attr('data-action');
                if (action == 'twitter') {
                    $(element).html(this.getConfig().twitterTpl);
                } else if(action == 'facebook') {
                    $(element).html(this.getConfig().facebookTpl);
                    (window.FB && FB.XFBML.parse($(element)[0]));
                } else if(action == 'google') {
                    $(element).html(this.getConfig().googleTpl);
                }
            }
        }
    });
});
