define('modules/sponsorbar/js/base.js',["app/config", "jquery", "app/util", "templates"], function (config, $, util, templates) {
    return {
        name: 'sponsors',
        selectors: '.sponsorBar, .sponsorFlyoutLogos, .sponsorLogoGrid',
        remoteData: [config.urls.sponsors],

        initialize: function (data) {
            var sponsorGroups = [],
                templateData = {
                    sponsorGroups: []
                };

            $.each(data, function (index, record) {
                if (!sponsorGroups[record.type]) {
                    sponsorGroups[record.type] = {
                        name: record.type,
                        records: []
                    };
                }
                sponsorGroups[record.type].records.push(record);
            });
            for (var key in sponsorGroups) {
                templateData.sponsorGroups.push(sponsorGroups[key]);
            }

            $('.sponsorBar, .sponsorFlyoutLogos, .sponsorLogoGrid').append(templates.sponsorlogobar(templateData));

        }
    }
});
