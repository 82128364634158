/*
 * This is the effect that creates a parallax scene
 */
define('modules/animations/scenebg/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "parallax"], function (module, config, $, util, templates, parallax) {
    return new module({
        name: 'scenebg',
        selectors: '.sceneWrap',
        remoteData: [],

        config: {
            enableZoom: false
        },

        routes: [{
            route: '.*',
            fn: 'changeScene',
            extra: true,
            delay: 300 // TODO - Refine this timeout if necessary to match nav animation timing
        },{
            route: '404',
            extra: true,
            fn: 'changeSceneMissingPage',
            delay: 500
        }],

        initialize: function () {

            $('.sceneWrapElements').append(templates.parallaxscenebg());

            this.setParallax();

            this.homeParallaxScroll();
        },

        methods: {

            setParallax: function () {
                $('body').livequery('.sceneWrapElements', $.proxy(function (el) {

                    var scaleFactor = 0.2; // Update this scaleFactor to be the max on the right side
                    var pxInstance = new parallax($(el).get(0), {
                        customHook: (this.getConfig().enableZoom ? function (e, x, y) {
                            // Potential TODO - Add throttling to match our transition value (currently 150ms)
                            var scale = parseFloat(1 + ( ((1 + x) / 2) * scaleFactor )).toFixed(5);
                            $('.zoom-el').get(0).setAttribute('style', 'transform:scale(' + scale + ');-webkit-transform:scale(' + scale + ');-moz-transform:scale(' + scale + ');-ms-transform:scale(' + scale + ');');
                        } : $.noop)
                    });

                }, this));

                $('body').livequery('.sceneWrapBackground', $.proxy(function (el) {
                    var pxInstance = new parallax($(el).get(0));
                }, this));

                $('body').livequery('.sceneWrapStars', $.proxy(function (el) {
                    var pxInstance = new parallax($(el).get(0));
                }, this));
            },

            homeParallaxScroll: function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(10, util.minScrollSpeed($.proxy(function (event) {


                    var scrollTop = $(window).scrollTop();
                    var distanceFromBottom = Math.floor($(document).height() - $(document).scrollTop() - $(window).height());

                    // desktop
                    if ($( window ).width() > 768) {
                        // scrolling down
                        if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                            if (scrollTop > 450 && distanceFromBottom > 700) {
                                $('.zoom-el').addClass('mid');
                            }
                            else if (distanceFromBottom < 700) {
                                $('.zoom-el').removeClass('mid').addClass('bottom');
                            }
                        }
                        // scrolling up
                        else {
                            if (distanceFromBottom < 600) {
                                $('.zoom-el').addClass('bottom').removeClass('mid');
                            }
                            else if (distanceFromBottom > 500 && scrollTop > 680) {
                                $('.zoom-el').removeClass('bottom').addClass('mid');
                            }
                            else if (scrollTop < 680) {
                                $('.zoom-el').removeClass('bottom mid');
                            }
                        }
                    }
                    // mobile
                    else {
                        if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                            if (scrollTop > 550 && distanceFromBottom > 650) {
                                $('.zoom-el').addClass('mid');
                            }
                            else if (distanceFromBottom < 650) {
                                $('.zoom-el').removeClass('mid').addClass('bottom');
                            }
                        }
                        // scrolling up
                        else {
                            if (distanceFromBottom < 650) {
                                $('.zoom-el').addClass('bottom').removeClass('mid');
                            }
                            else if (distanceFromBottom > 650 && scrollTop > 600) {
                                $('.zoom-el').removeClass('bottom').addClass('mid');
                            }
                            else if (scrollTop < 550) {
                                $('.zoom-el').removeClass('bottom mid');
                            }
                        }
                    }

                    this.lastScrollTop = scrollTop;


                }, this))));
            },

            changeScene: function (hash) {

                $(this.getSelectors()).removeClass('scene-missing-page');
                $(this.getSelectors()).find('.zoom-el').addClass('transition-out');

                hash = (hash || 'home');

                if(!(/(register|login|forgot|reset)/).test(hash)) {
                    $('#mainNavbar').removeClass(function (index, className) {
                        return (className.match(/(^|\s)nav-page-\S+/g) || []).join(' ');
                    }).addClass('nav-page-' + hash.replace('#', ''));

                    $('.sceneWrap').removeClass(function (index, className) {
                        return (className.match(/(^|\s)scene-\S+/g) || []).join(' ');
                    }).addClass('scene-' + hash.replace('#', ''));

                    $('.zoom-el').removeClass(function (index, className) {
                        return (className.match(/(^|\s)zoom-el-\S+/g) || []).join(' ');
                    }).addClass('zoom-el-' + hash.replace('#', ''));

                    setTimeout(function () {
                        $('html,body').animate({
                            scrollTop: 0
                        }, 1000, 'swing');
                    }, 1000);
                }

            },

            changeSceneMissingPage: function() {
                $(this.getSelectors()).removeClass(function (index, className) {
                    return (className.match(/(^|\s)scene-\S+/g) || []).join(' ');
                }).addClass('scene-missing-page');
                $('.zoom-el').removeClass(function (index, className) {
                    return (className.match(/(^|\s)zoom-el-\S+/g) || []).join(' ');
                }).addClass('zoom-el-missing-page');
                setTimeout(function(){
                    $('.zoom-el').removeClass('transition-out');
                },1500);
            }
        }
    });
});
