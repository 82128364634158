define('modules/portraitmask/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'portraitmask',
        selectors: false,
        remoteData: [],

        initialize: function () {
            // Render the template
            $('body').append(templates.portraitmask());
        }
    }
});
