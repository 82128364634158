define('app/user',['app/util','jquery','app/config','jquery.cookie'],function(util,$,config){
    return {

        userStatus: null,

        roles: {
            registered: function() {
                return Number(this.userStatus) >= 1;
            },
            loggedin: function() {
                return Number(this.userStatus) == 2;
            },
            admin: function() {
                return (window.chatConfig && window.chatConfig['isadmin']);
            }
        },

        init: function() {
            this.refresh();
        },

        refresh: function() {
            if(!this.cookieName){
                this.cookieName = config.environment + '-userStatus';
            }
            this.userStatus = $.cookie(this.cookieName);
        },

        checkRole: function(role) {
            this.refresh();
            return (this.roles[role] && $.proxy(this.roles[role],this)());
        }

    }
});
