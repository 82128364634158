define('modules/lastcard/basic/js/base.js',["app/module", "app/config","jquery","app/util","templates"], function(module,config,$,util,templates) {
    return new module({
        name: 'lastcard',
        selectors: '.lastCard',
        remoteData: [],

        initialize: function () {
            $(this.getSelectors()).append(templates.lastcard({
                config: config
            }));
        },

        onRender: function() {
            $('body').trigger('resetskrollr');
        }
    });
});
