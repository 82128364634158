define('app/features/expandergrid',['jquery','app/util'],function($,util){
    return {
        feature: true,
        element: null,

        getActiveColumn: function() {
            return $(this.element).find('.inner-col[data-active="true"]').first();
        },

        init: function(element) {

            if(!element) {
                element = $('.expanderGrid');
            }

            this.element = element;

        	// hide any inner-cols that are empty
        	$(element).find('.inner-col').each(function(){
        		if ( ( !$.trim( $('h3', this).html() ).length ) || ( !$.trim( $('.hidden.innerSwap', this).html() ).length ) ) {
        			$(this).remove();
        		
        		}
        	});

            // Append the initially hidden elements
            $(element).append($('<div></div>').addClass('activeBox'));
            $(element).append($('<div><div class="expanderContent"></div><div class="expanderNav"><i class="left-arrow prev material-icons">play_arrow</i><i class="right-arrow next material-icons">play_arrow</i><i class="close material-icons">close</i></div></div></div>').addClass('row detailRow'));



            // Set up learn section animations and hover handling
            /*$(element).find('.inner-col').hover(function(e){
            	$('.activeBox').show();
                $(element).find('.activeBox').css({
                    top: $(this).offset().top-$(element).offset().top,
                    left: $(this).offset().left-15-$(element).offset().left
                });
                $(element).find('.activeBox').html($(this).html());
            }, $.proxy(function(e){
                var activeColumn = this.getActiveColumn();
                if($('.learnSection .detailRow:visible').length > 0) {
                    $('.activeBox').css({
                        top: $(activeColumn).offset().top-$(element).offset().top,
                        left: $(activeColumn).offset().left-15-$(element).offset().left
                    });
                    $('.activeBox').html($(activeColumn).html()).css('opacity',1);
                }
            },this));*/
            

            // Move and reveal the extra description text on click
            $(element).find('.inner-col').click(function(e){
            	
                $(this).parents('.expanderGrid').css('pointer-events','none');

                // Set the active attribute on clicked column
                $(element).find('.inner-col').attr('data-active',false);
                
                // append up arrow to grid border
                $(element).find('.right-arrow').not($(element).find('.right-arrow.next')).remove();
                $(this).attr('data-active',true).append('<i class="right-arrow"></i>');

                // Update and move description row
                var parentRow = $(this).parents('.row').first();
                $(element).find('.detailRow .expanderContent').html($(this).find('.innerSwap').html());
                if($(this).width()*2 > $(element).width()) {
                    $(element).find('.detailRow').insertAfter($(this));
                } else {
                    $(element).find('.detailRow').insertAfter(parentRow);
                }
                $(element).find('.detailRow').fadeIn({
                    complete: $.proxy(function() {
                        $(this).trigger('mouseenter'); // Trigger hover for repositioning as DOM moves
                    },this)
                });
                $('html, body').animate({
        			scrollTop: $(this).offset().top
    			}, {
                    duration: 500,
                    complete: $.proxy(function() {
                        $(this).parents('.expanderGrid').css('pointer-events','all');
                    },this)
                });
            });
			
			// get total amount of grid items
			var gridCount = 0;
			 $(element).find('.inner-col').each(function(e){
			 	gridCount++;
			 });
			 
			 // minus one since zero index
			 var gridCountIndex = gridCount - 1;
			
			
            // Nav handlers
            $(element).find('.expanderNav i').on('click', $.proxy(function(e){
                var activeIndex = $(element).find('.inner-col').index($(this.getActiveColumn()));
                
				if($(e.target).hasClass('next')) {
					// if we are at the end of the grid items
					// set index to -1 to land on 0 on click next
					
					if (activeIndex == gridCountIndex) {
						activeIndex = -1;
					}
                    $(element).find('.inner-col').eq(activeIndex+1).trigger('click');
						
					
				} else if($(e.target).hasClass('prev')) {
                    $(element).find('.inner-col').eq(activeIndex-1).trigger('click');
				}

                
                /*if($(e.target).hasClass('next') && activeIndex+1 <= $(element).find('.inner-col').length) {
                 $(element).find('.inner-col').eq(activeIndex+1).trigger('click');
                } else if((!$(e.target).hasClass('close')) && (activeIndex-1 >= 0)) {
                 $(element).find('.inner-col').eq(activeIndex-1).trigger('click');
                }*/
                
                
                
            },this));
            $(element).find('.expanderNav i.close').on('click', $.proxy(function(e){
            	$(element).find('.right-arrow').not($(element).find('.right-arrow.next')).remove();
            	$('.detailRow').fadeOut(300);
                $(element).find('.inner-col').removeAttr('data-active');
            },this));


            // hide the active box and detail row
            // when resizing the browser then show
            // on release
            $( window ).load(function() {
                var resizeId;
                $(window).resize(function() {
                    $('.activeBox').hide();
                    $(element).find('.detailRow').hide();
                    clearTimeout(resizeId);
                    resizeId = setTimeout(doneResizing, 500);
                });


                function doneResizing(){
                    if ($(element).find('.inner-col').is("[data-active]")) {
                        $('.activeBox').show();
                        $(element).find('.detailRow').show();
                        $(element).find('.inner-col[data-active="true"]').trigger('click');
                    }
                    else {
                        //$('.learnSection').trigger('mouseenter');
                        //$('.activeBox').show();
                    }

                }
            });
            
        }
    }
});



    
