// TODO - MERGE - Relevant changes from 100-change
define('app/features/modal',["app/config","jquery","app/router"], function(config,$,router) {
    return {

        ready: false,
        feature: true,

        tpl: '<div class="actionWrap modal slideIn section layout-vertical-center layout-auto-height" data-bgimage="default-body"><div class="modal-close icon-icon-close modal-mobile"></div><div class="modalWrap"><div class="contentWrap"></div></div></div>',

        config: {
            //animation: 'slideLeftIn'
            animation: 'modalSlideAnimation'
        },

        init: function() {
            $('.modalActionWrap').append($(this.tpl)).modal({
                show: false,
                backdrop: true
            });
            if(this.config.animation) {
                $('.actionWrap.modal').addClass(this.config.animation);
            }
            this.addFormModalListeners();
            this.ready = true;
            
        },
        
        getEl: function() {
            return $('.actionWrap.modal');
        },

        // Method that loads content into the modal
        // Second param defaults to true to immediately show the modal
        refreshContent: function(content,show) {
        	$('.form, .modal-container, .messageWrap, .contentModalWrap').removeClass('wic-modal-inner-animation'); // TODO - Make this project generic
            /*if(!this.ready) {
                this.init(); // TODO - Add back in self-initalization
            }*/

            if(show || show === undefined) {
                $('.actionWrap.modal').modal('show');
            }
            $('.actionWrap.modal .contentWrap').removeClass('fadeIn');
            $('.actionWrap.modal .contentWrap').html(content);
            $('.actionWrap.modal .contentWrap .modal-container .modal-header span').css('opacity','0');
            $('.actionWrap.modal .contentWrap .modal-container .modal-header h1').css('opacity','0');
            $('.actionWrap.modal .contentWrap .modal-container .form-content-container').css('opacity','0');
            $('.actionWrap.modal .contentWrap .toggle-screen a').css('opacity','0');
            //$('.actionWrap.modal .contentWrap').html(content);
            //$('.daysToRegister a.navreg').hide();
            $('.daysToRegister a.modal-close').show();
            $('.daysToRegister a.navreg').addClass('back-to-btn');
            $('.daysToRegister a.modal-close').addClass('back-to-btn');
			
			// this is specific to WIC
            $('.form, .modal-container, .messageWrap, .contentModalWrap').addClass('wic-modal-inner-animation'); // TODO - Make this project generic
     		setTimeout(function(){
            	$('.actionWrap.modal .contentWrap').addClass('fadeIn');
            	$('.actionWrap.modal .contentWrap .modal-container .modal-header span').css('opacity','1');
            	$('.actionWrap.modal .contentWrap .modal-container .modal-header h1').css('opacity','1');
            	$('.actionWrap.modal .contentWrap .modal-container .form-content-container').css('opacity','1');
            	$('.actionWrap.modal .contentWrap .toggle-screen a').css('opacity','1');
            },1400);
        },

        // Closes the modal, with a delay if animation is used
        closeModal: function() {
        	$('.form, .modal-container').removeClass('wic-modal-inner-animation'); // TODO - Make this project generic
            if(this.config.animation) {
                $('.actionWrap.modal').removeClass('in');
                $('.toggle-screen').removeClass('in');
                $('.modal-container').removeClass('wic-modal-inner-animation');
                
                $('.actionWrap.modal .contentWrap').removeClass('fadeIn');
                $('.actionWrap.modal .fixedBg li').animate({
    				opacity: 0
  				}, 300, function() {
  				
					setTimeout(function(){
						$('.actionWrap.modal').removeClass('in');
						$('.actionWrap.modal .fixedBg li').css('opacity','');
					},1000);
    				
  				});
                //$('.actionWrap.modal').removeClass('in');
                
                $('.toggle-screen').removeClass('in');
                setTimeout(function(){
                    $('.actionWrap.modal').modal('hide');
                },1500);
            } else {
                $('.actionWrap.modal').modal('hide');
            }
            $('body').removeClass('locked form-modal')
            $('.form, .modal-container').removeClass('wic-modal-inner-animation'); // TODO - Make this project generic
            $('body').trigger('bgvideoreset');        
        },

        showModal: function(){
            $('.actionWrap.modal').modal('show');
        },
        
        addFormModalListeners: function() {

            // This happens *after* a modal is hidden
            $('.actionWrap.modal').on('hidden.bs.modal',function(){
                $('.pageContainer.active, .footerWrap').show(); // Show our last active page and footer
                $('body').scrollTop($('body').data('scroll')); // Scroll back to our previous point on the page
                //$('.daysToRegister a.navreg').show();
                $('.daysToRegister a.modal-close').hide();
                $('.daysToRegister a.navreg').removeClass('back-to-btn');
            	$('.daysToRegister a.modal-close').removeClass('back-to-btn');
            });

            // This happens *before* a modal is shown
            $('.actionWrap.modal').on('show.bs.modal',function() {
                $('body').data('scroll',$('body').scrollTop()); // Save our body scroll position
                $('.actionWrap.modal > .contentWrap').empty(); // Empty anything existing
                setTimeout(function(){
                	$('body').addClass('locked form-modal');
                	$('.toggle-screen').addClass('in');
                },500);
            });

            // This happens *after* a modal is shown
            $('.actionWrap.modal').on('shown.bs.modal',function(){
            	// TODO line below commented out works for WIC not MIT
                //$('.pageContainer.active, .footerWrap').hide();
                //$('.footerWrap').hide();
            });

            // Click handlers
            $('.actionWrap.modal').livequery('.modal-close, .modal-close',$.proxy(function(el){
                $(el).on('click', $.proxy(this.closeModal,this));
            },this));
            $('.actionWrap.modal').delegate('.modal-back','click',this.onBackArrow);
        },

        onBackArrow: function(e) {
            e.preventDefault();
            window.location.hash = router.routeHistory.pop();
        },

        // EVERYTHING BELOW THIS LINE IS THE V2 IMPLEMENTATION, INITIALLY USED FOR PRIZEWARE
        create: function(newEl,config) {
            newEl = $(newEl);
            $(parent.window.document).find('body').first().append(newEl);
            $(newEl).modal(config || {});
            $(newEl).on('hidden.bs.modal',function(){
                $(newEl).remove();
            });
            return $(newEl);
        },

        hide: function(selector) {
            var modalEl = $(parent.window.document).find('body '+selector);
            if(modalEl.length > 0) {
                $(modalEl).modal('hide');
            }
        }
    }
});
